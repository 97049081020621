/*
 * Copyright 2023-2024 NXP
 */

const COLORS = {
  grey: {
    100: "#F5F5F5",
    200: "#d8d8d8",
    300: "#bfbfbf",
    400: "#A8A8A8",
    600: "#777777",
    800: "#464646",
    900: "#343a40",
  },
  green: "#69CA00", // nxp green
  teal: {
    100: "#ECF4F3",
    200: "#78ABA7",
    400: "#408E88",
    500: "#3a8589",
    900: "#074C42",
  },
  blue: {
    200: "#d1ecf1",
    400: "#0EAFE0", // nxp blue
    500: "#0288d1",
    700: "#0971f1",
  },
  orange: {
    500: "#FCA600", // nxp color
    600: "#ED6C02",
  },
  red: {
    700: "#dc3545",
    800: "#D32F2F",
  },
  black: "#3C3C3C",
};

export default COLORS;
