/*
 * Copyright 2023-2024 NXP
 */

"use client";

import apolloClient from "@/src/lib/graphql";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import styles from "@/src/components/styles";
import { createTheme, CssBaseline, styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import LoadedWrapper from "@/src/components/LoadedWrapper/LoadedWrapper";
import FilterSnackbar from "@/src/components/UI/FilterSnackbar/FilterSnackbar";
import NextTopLoader from "nextjs-toploader";
import { Poppins } from "next/font/google";
import { ThemeProvider } from "@mui/material/styles";

const StyledSnackbarProvider = styled(SnackbarProvider)`
  #notistack-snackbar {
    margin: auto;
  }
`;

declare module "notistack" {
  interface VariantOverrides {
    filterSnackbar: { header: string; itemName?: string };
  }
}
export const poppins = Poppins({
  weight: ["400", "500", "600", "700"],
  subsets: ["latin"],
  display: "swap",
});

const theme = createTheme({
  typography: {
    fontFamily: poppins.style.fontFamily,
  },
});

export function RootProviders({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <StyledSnackbarProvider
          Components={{
            filterSnackbar: FilterSnackbar,
          }}
          anchorOrigin={styles.snackbarAnchorTop}
          maxSnack={1}
          preventDuplicate
        >
          <CssBaseline />
          <NextTopLoader
            color="rgb(14,175,224, 0.8)"
            height={4}
            showSpinner={false}
          />
          <LoadedWrapper>{children}</LoadedWrapper>
        </StyledSnackbarProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}
