/*
 * Copyright 2024 NXP
 */

import COLORS from "@/src/lib/colors";

const styles = {
  snackbarCard: { maxWidth: 400 },
  infoIcon: { color: COLORS.teal[900] },
  headerText: {
    color: COLORS.teal[900],
    fontWeight: "bold",
    fontSize: "medium",
    width: 200,
  },
};

export default styles;
