/*
 * Copyright 2023-2024 NXP
 */

import fetch from "cross-fetch";
import {
  ApolloClient,
  createHttpLink,
  from,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { API_URI, UNAUTHORIZED } from "./const";
import { relayStylePagination } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql/error";

const httpLink = createHttpLink({
  uri: API_URI,
  fetch,
  credentials: process.env.NEXT_PUBLIC_CREDENTIALS
    ? process.env.NEXT_PUBLIC_CREDENTIALS
    : "include",
});

export const GetGqlErrorsQuery = gql`
  query GetGqlErrors {
    gqlErrors @client
  }
`;

const retryLink = new RetryLink();

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const { cache } = operation.getContext();

  const parseError = ({
    message,
    locations,
    path,
    extensions,
  }: GraphQLError) => {
    console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    );

    if (extensions?.code === UNAUTHORIZED) {
      // reload page if unauthorized - it will redirect by SSR user for AuthContext
      location.reload();
    }
  };

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      parseError(err);
    });

    if (operation.getContext().errorHandledLocally) return; // Check this into the queries performed throughout the app

    cache.writeQuery({
      query: GetGqlErrorsQuery,
      data: {
        gqlErrors: graphQLErrors.filter(
          (err) => err.extensions && err.extensions.code !== UNAUTHORIZED,
        ),
      },
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export async function fetchWithRetry(url: string, options: object, retries: number = 15, delay: number = 1000) {
  try{
    const response = await fetch(url, options);

    if(!response.ok){
      throw new Error(`HTTP error. Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if(contentType && contentType.indexOf("application/json") !== -1){
      return await response.json();
    }else{
      throw new Error("Invalid content type.");
    }
  }catch (error){
    if(retries > 0){
      console.log("Retrying request ...");
      await new Promise(res => setTimeout(res, delay));
      return fetchWithRetry(url, options, retries -1, delay)
    }else{
      throw new Error(`Oops API is not online. Please try again later.`);
    }
  }
};

const apolloClient = new ApolloClient({
  link: from([retryLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          myDashboard: relayStylePagination(),
          buildingArchives: {
            merge(existing = [], incoming: any[]) {
              return incoming;
            },
          },
          myNotifications: relayStylePagination(),
        },
      },
    },
  }),
});

export default apolloClient;
