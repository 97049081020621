/*
 * Copyright 2023-2024 NXP
 */

import COLORS from "@/src/lib/colors";

const styles = {
  errorContainer: { height: "80vh" },
  pageErrorIcon: {
    color: COLORS.teal[900],
    display: { xs: "none", sm: "initial" },
  },
  pageErrorText: { textAlign: { sm: "center" } },
  pageErrorActionButtons: {
    width: { xs: "100%", sm: "auto" },
    paddingTop: 1,
  },
  actionButton: { width: 300 },
  layoutOuterContainer: { display: "flex" },
  layoutInnerContainer: (withDrawer: boolean) => ({
    minHeight: "100vh",
    flexGrow: 1,
    height: withDrawer ? "100%" : "100vh",
    padding: withDrawer ? 3 : 0,
    paddingBottom: 0,
  }),
  snackbarAnchorTop: {
    vertical: "top" as const,
    horizontal: "center" as const,
  },
  spinner: { color: COLORS.teal[900] },
  divider: { marginY: 1 },
};

export default styles;
