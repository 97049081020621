/*
 * Copyright 2023-2024 NXP
 */

"use client";

import React, { useEffect, useState } from "react";

const LoadedWrapper = ({ children }: { children: React.ReactNode }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return loaded && children;
};

export default LoadedWrapper;
