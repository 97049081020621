/*
 * Copyright 2024 NXP
 */

import React, { forwardRef, useCallback } from "react";
import { Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import COLORS from "@/src/lib/colors";
import IconButton from "@mui/material/IconButton";
import { CloseIcon } from "next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import styles from "./styles";

export const filterSnackbarVariables = {
  anchorOrigin: {
    vertical: "top" as const,
    horizontal: "right" as const,
  },
  autoHideDuration: 3000,
};

interface FilterSnackbarProps extends CustomContentProps {
  header: string;
  itemName: string;
}

const FilterSnackbar = forwardRef<HTMLDivElement, FilterSnackbarProps>(
  ({ id, message, header, itemName }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} data-testid={"toast-message"}>
        <Card variant="outlined" sx={styles.snackbarCard}>
          <CardHeader
            p={1.5}
            avatar={<InfoIcon sx={styles.infoIcon} />}
            title={header}
            titleTypographyProps={styles.headerText}
            action={
              <IconButton
                aria-label="close-snackbar"
                size="small"
                onClick={handleDismiss}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
              flexWrap="wrap"
            >
              {itemName && (
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color={COLORS.teal[900]}
                >
                  {itemName}
                </Typography>
              )}
              <Typography variant="body2">{message}</Typography>
            </Stack>
          </CardContent>
        </Card>
      </SnackbarContent>
    );
  },
);

FilterSnackbar.displayName = "FilterSnackbar";

export default FilterSnackbar;
